<template>

<div v-bind:class="{'container-fluid': currentPesquisa != null,  '': currentPesquisa==null}">
          <!-- Page Heading -->
           <div v-if="currentPesquisa != null" class="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 class="h3 mb-0 text-gray-800">{{nomePesquisa}}</h1>
            </div>
            <div v-if="currentPesquisa != null" class="mb-12" style="justify-content: space-between; display: flex">
          </div>
          <!-- Content Row -->
          <!-- Content Row -->
          <div class="row">
            <!-- Chart -->
            <div style="padding-top: 30px;padding-bottom: 50px;" v-bind:class="{'mx-auto col-lg-7': currentPesquisa != null,  'mx-auto col-lg-12 p-0 m-0': currentPesquisa==null}"  v-if="!messageSuccess">
              <!-- table -->
              <form v-on:submit="sendAnswer" v-on:submit.prevent>
                <div style="width: 600px;" class="card shadow mb-4">
                  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 v-if="pesquisa != null" class="m-0 font-weight-bold text-primary">{{ pesquisa[0].Title}}</h6>
                  </div>
                  <div class="card-body">
                    <div v-for="(pesquisa, pkey, pindex) in pesquisa" :key="`${pkey}-${pindex}`">
                      <h5 class="">{{pesquisa.questionText}}</h5>
                      <div class="row my-3">
                        <div class="col-lg-6 d-flex align-items-center">{{pesquisa.questionLabel}}</div>
                        <div class="col-lg-6" v-if="pesquisa.SurveyTypeID == 1"><!--RATE-->
                          <div class="rate">
                            <input type="radio" id="star5" v-bind:name="pesquisa.SurveyType" value="5">
                            <label class="star" for="star5" title="text">5 stars</label>
                            <input type="radio" id="star4" v-bind:name="pesquisa.SurveyType" value="4">
                            <label for="star4" title="text">4 stars</label>
                            <input type="radio" id="star3" v-bind:name="pesquisa.SurveyType" value="3">
                            <label for="star3" title="text">3 stars</label>
                            <input type="radio" id="star2" v-bind:name="pesquisa.SurveyType" value="2">
                            <label for="star2" title="text">2 stars</label>
                            <input type="radio" id="star1" v-bind:name="pesquisa.SurveyType" value="1">
                            <label for="star1" title="text">1 star</label>
                          </div>
                        </div>

                        <div class="col-md-12" style="width:100%;display:none" v-bind:id="pesquisa.SurveyType">
                          <h6 class="text-danger">Este campo é obrigatório</h6>
                          <hr style="background-color:red">
                        </div>


                        <!-- :disabled="mailingPesquisa == null" -->

                        <div class="col-lg-12 px-4" v-if="pesquisa.SurveyTypeID == 2"><!--INTERATIVO-->
                          <div class="form-check d-flex align-items-center my-1" v-for="(interative, ikey, iindex) in pesquisa.InterativeQuestions" :key="`${ikey}-${iindex}`">
                            <input  class="form-check-input" type="radio" v-bind:data-interativename="interative.Value"  v-bind:name="pesquisa.SurveyType" v-bind:id="getIndexName(ikey)" v-bind:value="interative.Value" checked="">
                            <label class="form-check-label" v-bind:for="getIndexName(ikey)"> </label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <div class="input-group-text border-0 wid40"><i v-bind:class="interative.Icon"></i> </div>
                              </div>
                              <input type="text" class="form-control border-right-0 border-top-0 border-bottom border-left-0 form-control-sm txt-bk" v-bind:id="interative.Value" 
                              v-bind:placeholder="interative.Label" v-bind:name="interative.Value">
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr v-if="(pkey % 2) == 0">
                    </div>
                    <div class="row">
                      <div v-if="!loading" class="col-lg-6 text-center"><input type="button" class="btn btn-secondary text-white btn-user pills mt-3 mb-2 float-right btn-sm" @click="pular()" value="Pular"/></div>
                      <div v-if="!loading" class="col-lg-auto text-center"><input type="submit" class="btn btn-primary text-white btn-user pills mt-3 mb-2 float-right btn-sm" value="Enviar"/></div>
                    <!--  <div class="col-lg-6 text-center col-7"><a class="btn btn-outline-primary btn-user pills mt-3 mb-2 float-left btn-sm" href="#">Pular pesquisa</a></div>-->
                    </div>

                    <ProgressLoading v-bind:loading="loading" />
                  </div>
                </div>
              </form>
            </div>

             <div class="mx-auto col-lg-7" v-if="messageSuccess">
                <h5 class="h3 mb-0 text-gray-800">Sua resposta é muito importante para nós, agradecemos o contato</h5>
             </div>
          </div>


        </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import ProgressLoading from '../../modules/auth/components/ProgressLoading.vue';


export default {
    name: 'Pesquisa',
    directives: {mask},
    components: {
        ProgressLoading
    },
    data: () => ({
        pesquisa:null,
        nomePesquisa:'',
        loading: false,
        messageSuccess:false
    }),
    methods: {
        ...mapActions('survey', ['ActionSurveyGet','ActionSetCurrentComp','ActionSurveyAnswer']),        
        async carregarPesquisa(){
          if(this.mailingPesquisa != null){
            this.pesquisa = this.mailingPesquisa;
            this.nomePesquisa = this.mailingPesquisa.Name

            window.jQuery("#page-top").css('margin-top',2);
            window.jQuery("#page-top").css('overflow','hidden');
            return;
          }

          this.loading = true;
          const response = await this.ActionSurveyGet({
            SurveyID:this.currentPesquisa.SurveyID,
            AccessCode:this.currentPesquisa.AccessCode
          }); 
          
          
          this.pesquisa = response.results;
          this.loading = false; 

          for(var n = 0; n <  this.pesquisa.length; n ++){
            let questionValue = this.pesquisa[n].SurveyQuestionValue;
            let interativeValue = this.pesquisa[n].SurveyQuestionInterativeValue;

            if(questionValue != null && interativeValue != null) {
              this.$router.push({ name: 'dashboard' });
            }

            let surveyType = this.pesquisa[n].SurveyType;
            if(questionValue != null ||  questionValue != ''){
              setTimeout(function(){
                window.jQuery("input:radio[name='"+surveyType+"'][value=" + questionValue + "]").prop('checked', true);
              },200);
              
               if(this.pesquisa[n].SurveyTypeID == 2){
                 let interativeValue = this.pesquisa[n].SurveyQuestionInterativeValue;

                 if(interativeValue != '' || interativeValue != null){
                     setTimeout(function(){
                        window.jQuery("#"+questionValue+"").val(interativeValue);
                      },200);
                    
                  return;
                 }

              }
            }
           
          }
        },

        pular(){
           this.$router.push({ name: 'dashboard' });
        },

        async sendAnswer(){
         
          let questionsRequest = [];
          let mailingCode = this.currentPesquisa.AccessCode;
          for(var n = 0; n < this.pesquisa.length; n ++){
             let questionValue = window.jQuery("input:radio[name='"+this.pesquisa[n].SurveyType+"']:checked").val();
             let interativeValue = '';
            
             window.jQuery("#"+this.pesquisa[n].SurveyType+"").hide();

             if(questionValue == '' || questionValue == null){
                window.jQuery("#"+this.pesquisa[n].SurveyType+"").show();
               return;
             }

              if(this.pesquisa[n].SurveyTypeID == 2){
                interativeValue = window.jQuery("input[name='"+questionValue+"']").val();
                 if(interativeValue == '' || interativeValue == null){
                    window.jQuery("#"+this.pesquisa[n].SurveyType+"").show();
                  return;
                 }

              }
                
             questionsRequest.push({
               MailingCode:mailingCode,
               SurveyQuestionID : this.pesquisa[n].SurveyQuestionID,
               SurveyQuestionType : this.pesquisa[n].SurveyTypeID,
               SurveyQuestionValue: questionValue,
               SurveyQuestionInterativeValue: interativeValue
             });
          }     

          try{
            let request = {answers:questionsRequest}

              this.loading = true;
              var result = await this.ActionSurveyAnswer(request);
              this.loading = false;
              if(!result.data.error){
                this.messageSuccess = true;
                this.$router.push({ name: 'dashboard' });
              }
          }catch{
            this.loading = false;
          }
         
        },

        getIndexName(index){
          return 'input' + index;
        },
        
        back() {
          this.ActionSetCurrentComp("comp-list");
          this.$destroy();
        },
              
        clear() {
            this.$v.$reset()
            this.form.cpf = ""
        }
    },
    mounted() {
        this.carregarPesquisa();
    },
    computed:{ 
      ...mapState('survey', ['currentPesquisa','mailingPesquisa'])
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




</style>
