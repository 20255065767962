<template>
    <div id="sitet" style="background:transparent">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <h1 class="mb-3">{{this.title}}</h1>
        </div>
        <div class="col-md-8 my-3">
          <!-- <p>Em breve  .</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Sobre",
  data: () => ({
    title:''
  }),
  methods: {

  },
  watch: {
    $route(to) {
      if(to.query.title == 'Home'){
        window.location.href = "/";
        return;
      }
      this.title = to.query.title;
    }
  },
  created() {
    this.title = this.$route.query.title;
  },
  computed: {
  }
};
</script>