<template>
    <div id="lp1">
    <div class="container">
      <div class="row" v-if="!initializing">
        <div class="mx-auto bg-white shadow rounded p-5 col-md-5 col-10 lpp" v-if="notExists">
            <p class="text-muted text-center small mb-1"> Código XPTO001 informado inválido ou inexistente </p>
        </div>
        <div class="mx-auto bg-white shadow rounded p-5 col-md-5 col-10 lpp" v-if="!notExists">
          <h5 class="">Olá </h5>
          <h6>CPF / CNPJ <span class="badge badge-light"> Digite os 4 primeiros números</span></h6>
          <p class="text-muted text-left small mb-1"> Por segurança, digite apenas os 4 primeiros dígitos do seu CPF ou CNPJ: </p>
          <form v-on:submit="doLogin" v-on:submit.prevent id="form">  
            <div class="input-group mb-3">
              <input type="text"  aria-label="Recipient's username" aria-describedby="basic-addon" class="form-control"
              required
              v-mask="'####'"
              :disabled="loading"
              v-model="form.accessdoc">
            
              <div class="input-group-append d-flex justify-content-start">
                <span class="input-group-text text-left" id="basic-addon">xx.xxx-xx ou x.xxx/xxxx-xx</span>
              </div>
            </div>
            <div class="form-group mb-3 ">
              <p class="text-left small mb-1 text-muted"> Digite o código de acesso que recebeu:</p> 
              <input type="text" class="form-control" placeholder="Código de acesso" id="form" label="Digite"
                v-model="form.accesscode"
                :disabled="loading">
                <div class="col-md-8" style="padding:0px;">
                </div>
            </div>

            <p class="small"> Aceito os <a class="text-secondary" data-target="#termos" data-toggle="modal" href="#">Termos de negociação.</a></p>
            <div class="form-group mb-3 ">

            <input
                  class="btn btn-secondary btn-lg text-white"
                  type="submit"
                  value="Prosseguir"
                  v-if="!loading"
              />
            </div>
          </form>
          <ProgressLoading v-bind:loading="loading" />
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mask } from 'vue-the-mask';

import ProgressLoading from "../../modules/auth/components/ProgressLoading.vue";


export default {
  directives: {mask},
  name: "LandingLayout",
  components: {
    ProgressLoading,
  },
  data: () => ({
      sheet: false,
      themeBackground : null,
      imageBackground : localStorage.heroImg,
      
      form: {
        accessdoc: null,
        accesscode:null
    },
    error: {
          alert: false,
          status: null,
          message: null
      },
    accessname:'',
    loading : false,
    notExists: false,
    initializing:true
  }),
  methods: {
    ...mapActions('auth', ['ActionMailingDoLogin', 'ActionDoLogin', 'ActionDoRegisterSession', 'ActionSetUser']),


    async doLogin(){

          this.loading = true;
          try {
              await this.ActionMailingDoLogin({
                  DocNumber: this.form.accessdoc,
                  AccessCode: this.form.accesscode,
                  DeviceName: process.env.VUE_APP_API_DEVICE
              })
              this.loading =  false;
              location.href = "/";
          } catch (error) {
              this.flashMessage.show({
              timeout:1000,
              status: 'error',
              title: 'Ocorreu um erro',
              message: 'Nenhum usuário foi encontrado!'});
              this.loading = false;
          }
    }

  },
  async created() {
    await this.ActionDoRegisterSession();
    var urlPath = this.$route.path;
    this.initializing = false;
    var paths = urlPath.split('/');

    this.form.accesscode = paths[paths.length -1];
    if(urlPath.includes('acessobf')){
        
        this.imageBackground = '/img/lp1.jpg'
      

        var head  = document.getElementsByTagName('head')[0];
       /* let indexCss = null;

        for(var item in head.children) {
          var href = head.children[item].href;
          if(href){
            if(head.children[item].href.includes('theme.css'))
              indexCss = head.children[item];
          }
        }*/

        var linkSCss ='/acesso/themebf.css';

        var link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = linkSCss;
        link.media = 'all';
        
        head.appendChild(link);

      /*  if(indexCss != null)
          head.removeChild(indexCss);*/

    }   
  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    classMessage: function () {
          return {
              'text-danger': this.error.status == "ERROR",  
              'text-muted': !this.error.status == "ERROR"
          }
      },
  }
};
</script>
